import React from 'react'
import styled from 'styled-components'

import ParseContent from 'components/shared/ParseContent'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 36px;
    & strong {
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
    }
  }
`

interface GoogleMapsInteriorProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_GoogleMapsInterior
}

const GoogleMapsInterior: React.FC<GoogleMapsInteriorProps> = ({ fields }) => (
  <section className="mb-5 pb-lg-5">
    <div className="container">
      <Content content={fields.description} className="mb-5" />
      <iframe
        title="Kokall"
        src={fields.iframe}
        width="100%"
        height={540}
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
      />
    </div>
  </section>
)

export default GoogleMapsInterior
